import './ProductionProcessPage.css';
import React from 'react';
import banner from '../../resource/productionProcessPage/banner.png';
import {useTranslation} from 'react-i18next';

export default function ProductionProcessPage() {

  const {t} = useTranslation();

  return (
    <div className="production-process-page">
      <div className="production-process-page-section-1">
        <div className="production-process-page-banner-container">
          <img src={banner} alt="Banner" className="production-process-page-banner"/>
        </div>
        <div className="production-process-page-section-1-content">
          <p>{t('productionProcessPage.section1.overview')}</p>
          <p>{t('productionProcessPage.section1.p7')}</p>
          <p>{t('productionProcessPage.section1.p8')}</p>
        </div>
        <div className="production-process-page-content">
          <h1>{t('productionProcessPage.section1.h1')}</h1>
          <p>1. <span>{t('productionProcessPage.section1.t1')}</span>: {t('productionProcessPage.section1.p1')}</p>
          <p>2. <span>{t('productionProcessPage.section1.t2')}</span>: {t('productionProcessPage.section1.p2')}</p>
          <p>3. <span>{t('productionProcessPage.section1.t3')}</span>: {t('productionProcessPage.section1.p3')}</p>
        </div>
        <div className="production-process-page-content" style={{padding: 64}}>
          <h1>{t('productionProcessPage.section1.h2')}</h1>
          <p>1. <span>{t('productionProcessPage.section1.t4')}</span>: {t('productionProcessPage.section1.p4')}</p>
          <p>2. <span>{t('productionProcessPage.section1.t5')}</span>: {t('productionProcessPage.section1.p5')}</p>
          <p>3. <span>{t('productionProcessPage.section1.t6')}</span>: {t('productionProcessPage.section1.p6')}</p>
        </div>
      </div>
    </div>
  );

}