import './IntroPage.css';
import {useTranslation} from 'react-i18next';
import banner1 from '../../resource/introPage/banner1.png';
import p1 from '../../resource/introPage/p1.png';
import p2 from '../../resource/introPage/p2.png';
import p3 from '../../resource/introPage/p3.png';
import p4 from '../../resource/introPage/p4.png';
import p5 from '../../resource/introPage/p5.png';
import p6 from '../../resource/introPage/p6.jpg';
import p7 from '../../resource/introPage/p7.png';
import p8 from '../../resource/introPage/p8.png';
import l1 from '../../resource/introPage/姚瑞鹏.png';
import l2 from '../../resource/introPage/徐建忠.png';
import l3 from '../../resource/introPage/汪济舟.png';
import l4 from '../../resource/introPage/许建军.png';
import l5 from '../../resource/introPage/姚元生.png';
import l6 from '../../resource/introPage/段景周.png';
import l7 from '../../resource/introPage/胡嘉才.png';
import l8 from '../../resource/introPage/胡冬林.png';
import React from 'react';
import {Image, List, Timeline} from 'antd';

export default function IntroPage() {

  const {t} = useTranslation();

  const leaderList = [
    {
      title: t('introPage.section3.l1'),
      avatar: l1,
      description: t('introPage.section3.l1-t'),
      content: t('introPage.section3.l1-d')
    },
    {
      title: t('introPage.section3.l2'),
      avatar: l2,
      description: t('introPage.section3.l2-t'),
      content: t('introPage.section3.l2-d')
    },
    {
      title: t('introPage.section3.l3'),
      avatar: l3,
      description: t('introPage.section3.l3-t'),
      content: t('introPage.section3.l3-d')
    },
    {
      title: t('introPage.section3.l4'),
      avatar: l4,
      description: t('introPage.section3.l4-t'),
      content: t('introPage.section3.l4-d')
    },
    {
      title: t('introPage.section3.l5'),
      avatar: l5,
      description: t('introPage.section3.l5-t'),
      content: t('introPage.section3.l5-d')
    },
    {
      title: t('introPage.section3.l6'),
      avatar: l6,
      description: t('introPage.section3.l6-t'),
      content: t('introPage.section3.l6-d')
    },
    {
      title: t('introPage.section3.l7'),
      avatar: l7,
      description: t('introPage.section3.l7-t'),
      content: t('introPage.section3.l7-d')
    },
    {
      title: t('introPage.section3.l8'),
      avatar: l8,
      description: t('introPage.section3.l8-t'),
      content: t('introPage.section3.l8-d')
    },
  ];

  return (
    <div className="intro-page">
      <div className="intro-page-section-1">
        <img src={banner1} alt="Banner 1" className="intro-page-banner-img"/>
        <div className="intro-page-section-1-content">
          <p className="intro-page-section-1-p">{t('overview.introPage.about-p1')}</p>
          <p className="intro-page-section-1-p">{t('overview.introPage.about-p2')}</p>
          <p className="intro-page-section-1-p">{t('overview.introPage.about-p3')}</p>
          <p className="intro-page-section-1-p">{t('overview.introPage.about-p4')}</p>
        </div>
      </div>
      <div className="intro-page-section-2">
        <h1>{t('introPage.section2.title')}</h1>
        <div className="intro-page-section-2-timeline">
          <Timeline
            mode="left"
            items={[
              {
                children: (
                  <>
                    <p>2002</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p1')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p1-2')}</p>
                    <div className="intro-page-section-2-timeline-img-container">
                      <Image
                        className="intro-page-section-2-timeline-img"
                        src={p1}
                      />
                    </div>
                  </>
                )
              },
              {
                children: (
                  <>
                    <p>2005</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p2')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p2-2')}</p>
                    <div className="intro-page-section-2-timeline-img-container">
                      <Image
                        className="intro-page-section-2-timeline-img"
                        src={p2}
                      />
                    </div>
                  </>
                )
              },
              {
                children: (
                  <>
                    <p>2012</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p3')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p3-2')}</p>
                    <div className="intro-page-section-2-timeline-img-container">
                      <Image
                        className="intro-page-section-2-timeline-img"
                        src={p3}
                      />
                    </div>
                  </>
                )
              },
              {
                children: (
                  <>
                    <p>2016</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p4')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p4-2')}</p>
                    <div className="intro-page-section-2-timeline-img-container">
                      <Image
                        className="intro-page-section-2-timeline-img"
                        src={p4}
                      />
                    </div>
                  </>
                )
              },
              {
                children: (
                  <>
                    <p>2017</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p5')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p5-2')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p5-3')}</p>
                    <div className="intro-page-section-2-timeline-img-container">
                      <Image
                        className="intro-page-section-2-timeline-img"
                        src={p5}
                      />
                    </div>
                  </>
                )
              },
              {
                children: (
                  <>
                    <p>2018</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p6')}</p>
                    <div className="intro-page-section-2-timeline-img-container">
                      <Image
                        className="intro-page-section-2-timeline-img"
                        src={p6}
                      />
                    </div>
                  </>
                ),
              },
              {
                children: (
                  <>
                    <p>2019</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p7')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p7-2')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p7-3')}</p>
                    <div className="intro-page-section-2-timeline-img-container">
                      <Image
                        className="intro-page-section-2-timeline-img"
                        src={p7}
                      />
                    </div>
                  </>
                )
              },
              {
                children: (
                  <>
                    <p>2021</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p8')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p8-2')}</p>
                    <p className="intro-page-section-2-timeline-p">{t('introPage.section2.p8-3')}</p>
                    <div className="intro-page-section-2-timeline-img-container">
                      <Image
                        className="intro-page-section-2-timeline-img"
                        src={p8}
                      />
                    </div>
                  </>
                )
              },
            ]}
          />
        </div>
      </div>
      <div className="intro-page-section-3">
        <h1>{t('introPage.section3.title')}</h1>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          itemLayout="horizontal"
          dataSource={leaderList}
          renderItem={(item, _index) => (
            <List.Item>
              <div className="intro-page-section-3-list-item">
                <div>
                  <img src={item.avatar} alt={'pic'} style={{maxWidth: 200}}/>
                </div>
                <div>
                  <h2>{item.title}</h2>
                  <h3>{item.description}</h3>
                  <p style={{fontSize: 16}}>{item.content}</p>
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );

}