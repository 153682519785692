import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import enTranslation from './translation/enTranslation.json';
import zhTranslation from './translation/zhTranslation.json';

const languages = {
  en: {
    translation: enTranslation,
  },
  zh: {
    translation: zhTranslation,
  },
};

i18n.use(
    initReactI18next,
).init(
    {
      resources: languages,
      lng: 'zh',
    },
).then();