import './CustomizationPage.css';
import banner from '../../resource/customizationPage/banner.png';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function CustomizationPage() {

  const {t} = useTranslation();

  return (
    <div className="customization-page">
      <div className="customization-page-section-1">
        <div className="customization-page-banner-container">
          <img src={banner} alt="Banner" className="customization-page-banner"/>
        </div>
        <div className="customization-page-content">
          <p>{t('customizationPage.overview')}</p>
          <h1>{t('customizationPage.section1.h1')}</h1>
          <p>1. {t('customizationPage.section1.i1')}</p>
          <p>2. {t('customizationPage.section1.i2')}</p>
          <p>3. {t('customizationPage.section1.i3')}</p>
          <p>4. section1{t('customizationPage.section1.i4')}</p>
        </div>
      </div>
    </div>
  );

}