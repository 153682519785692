import './HomePage.css';
import {Carousel} from 'antd';
import banner1 from '../resource/homePage/banner1.png';
import banner2 from '../resource/homePage/banner2.png';
import banner3 from '../resource/homePage/banner3.png';
import section3I1 from '../resource/homePage/section3-i1.png';
import section3I2 from '../resource/homePage/section3-i2.png';
import section3I3 from '../resource/homePage/section3-i3.png';
import section4I1 from '../resource/homePage/section4-i1.png';
import section4I2 from '../resource/homePage/section4-i2.png';
import section4I3 from '../resource/homePage/section4-i3.png';
import section4I4 from '../resource/homePage/section4-i4.png';
import section4I5 from '../resource/homePage/section4-i5.png';
import section4I6 from '../resource/homePage/section4-i6.png';
import section4I7 from '../resource/homePage/section4-i7.png';
import section4I8 from '../resource/homePage/section4-i8.png';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function HomePage() {

  const {t} = useTranslation();

  return (
    <div className="home-page">
      <div className="home-page-section-1">
        <div className="home-page-section-1-title">
          <h1>{t('homePage.section1.title')}</h1>
        </div>
        <Carousel arrows infinite autoplay pauseOnHover={false} autoplaySpeed={4000}>
          <div className="home-page-banner-container">
            <img src={banner1} alt="Banner 1" className="home-page-banner-img"/>
          </div>
          <div className="home-page-banner-container">
            <img src={banner2} alt="Banner 2" className="home-page-banner-img"/>
          </div>
          <div className="home-page-banner-container">
            <img src={banner3} alt="Banner 3" className="home-page-banner-img"/>
          </div>
        </Carousel>
      </div>
      <div className="home-page-section-2">
        <div className="home-page-section-2-content">
          <h1 style={{marginTop: 0}}>Top 1</h1>
          <p>{t('homePage.section2.h1')}</p>
          <h1>Top 2</h1>
          <p>{t('homePage.section2.h2')}</p>
          <h1>Top 3</h1>
          <p>{t('homePage.section2.h3')}</p>
        </div>
      </div>
      <div className="home-page-section-3">
        <div className="home-page-section-3-img-container">
          <img src={section3I1} alt="cotton" className="home-page-section3-img"/>
        </div>
        <div className="home-page-section-3-content">
          <h1 style={{marginTop: 0}}>{t('homePage.section3.h1')}</h1>
          <p>{t('homePage.section3.p1')}</p>
        </div>
        <div className="home-page-section-3-img-container">
          <img src={section3I2} alt="green" className="home-page-section3-img"/>
        </div>
        <div className="home-page-section-3-content">
          <h1 style={{marginTop: 0}}>{t('homePage.section3.h2')}</h1>
          <p>{t('homePage.section3.p2')}</p>
        </div>
        <div className="home-page-section-3-img-container">
          <img src={section3I3} alt="lyocell" className="home-page-section3-img"/>
        </div>
        <div className="home-page-section-3-content">
          <h1 style={{marginTop: 0}}>{t('homePage.section3.h3')}</h1>
          <p>{t('homePage.section3.p3')}</p>
        </div>
      </div>
      <div className="home-page-section-4">
        <div className="home-page-section-4-img-container">
          <div className="home-page-section-4-overlay">
            <p>{t('homePage.section4.p2')}</p>
          </div>
          <img src={section4I2} alt="warping" className="home-page-section4-img"/>
        </div>
        <div className="home-page-section-4-img-container">
          <div className="home-page-section-4-overlay">
            <p>{t('homePage.section4.p1')}</p>
          </div>
          <img src={section4I1} alt="spinning" className="home-page-section4-img"/>
        </div>
        <div className="home-page-section-4-img-container">
          <div className="home-page-section-4-overlay">
            <h1>{t('homePage.section4.h3')}</h1>
            <p>{t('homePage.section4.p3')}</p>
          </div>
          <img src={section4I3} alt="printing" className="home-page-section4-img"/>
        </div>
      </div>
      <div className="home-page-section-4">
        <div className="home-page-section-4-img-container">
          <div className="home-page-section-4-overlay">
            <p>{t('homePage.section4.p5')}</p>
          </div>
          <img src={section4I5} alt="dye house" className="home-page-section4-img"/>
        </div>
        <div className="home-page-section-4-img-container">
          <div className="home-page-section-4-overlay">
            <p>{t('homePage.section4.p4')}</p>
          </div>
          <img src={section4I4} alt="homw textile" className="home-page-section4-img"/>
        </div>
        <div className="home-page-section-4-img-container">
          <div className="home-page-section-4-overlay">
            <p>{t('homePage.section4.p6')}</p>
          </div>
          <img src={section4I6} alt="weaving" className="home-page-section4-img"/>
        </div>
      </div>
      <div className="home-page-section-4">
        <div className="home-page-section-4-img-container">
          <div className="home-page-section-4-overlay">
            <p>{t('homePage.section4.p7')}</p>
          </div>
          <img src={section4I7} alt="show room" className="home-page-section4-img"/>
        </div>
        <div className="home-page-section-4-img-container">
          <div className="home-page-section-4-overlay">
            <p>{t('homePage.section4.p8')}</p>
          </div>
          <img src={section4I8} alt="cooperation" className="home-page-section4-img"/>
        </div>
      </div>
    </div>
  );

}