import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import RootPage from './page/RootPage';
import {ConfigProvider} from 'antd';
import {useTranslation} from 'react-i18next';

function App() {

  const {i18n} = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem('language');
    if (lang === null) {
      localStorage.setItem('language', 'zh');
    } else if (lang === 'en') {
      i18n.changeLanguage('en').then();
    }
  }, [i18n]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Noto Sans SC", sans-serif',
          colorPrimary: '#14258a'
        },
        components: {
          Layout: {
            headerPadding: '0 8px'
          },
          Carousel: {
            arrowSize: 32,
            arrowOffset: 32
          },
          Timeline: {
            fontSize: 20,
          },
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path={'/*'} element={<RootPage/>}></Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );

}

export default App;
